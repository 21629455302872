import svgTwo from '../../images/2.gif';
import svgThree from '../../images/3.png';

export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Features',
  headline: '',
  description:
    'Recursively is a recursive type-1 zkEVM and as such it is Ethereum-equivalent at the consensus level whereas the recursivity component servers as an enhancement to increase the respective number of transactions per second compared to a simple type-1 zkEVM that features a significantly smaller number of TPS. As a fully Ethereum-equivalent rollup, the Recursively rollup protocol will enable dApp developers and users to leverage the power of Ethereum Layer 1 (L1) without making any changes to their existing dApps.',
  
  imgStart: false,
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Architecture',

  description:
    'There are five main components to Recursively protocol’s technical structure: the zkEVM circuits (for proof generation), the Layer 2 rollup node (for management of the rollup chain), the protocol on L1 (for connecting these two parts together for rollup protocol verification) as well as the Lurk based frontend and the Nova based backend for poly-iterative recursive proof system.  ',
 
  imgStart: true,
  img: svgTwo,
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true,
};