import React from 'react';

import {

  FaMedium,
  FaTwitter,
  FaDiscord,
  FaTelegram,
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLink,
  FooterLinkTitle,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from './FooterElements';
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
             

            
            </FooterLinksWrapper>

           
          </FooterLinksContainer>

          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to='/' onClick={toggleHome}>
             
              </SocialLogo>
              <br></br>
            
              <SocialIcons>
            
               
                 
             
               
               
                <SocialIconLink
                  href='https://twitter.com/rec_zk_evm'
                  target='_blank'
                  aria-label=''
                >
                 <FaTwitter/>
                </SocialIconLink>
            
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
