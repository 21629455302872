import React, { useState } from 'react';

import video from '../../videos/video.mov';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements';
import { Button } from '../ButtonElement';

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <HeroContainer id='home'>
        <HeroBg>
          <VideoBg autoPlay loop muted src={video} type='video/mp4' />
        </HeroBg>

        <HeroContent>
          <HeroH1>Poly-iterative type-1 recursive zkEVM</HeroH1>
          <HeroP>
          Mainnet launch Q1 2025
          </HeroP>
       
          <HeroBtnWrapper>
            <Button
               as="a" href="https://www.dropbox.com/scl/fi/6mckx7xyh7oiomzavolhw/The-Recursively-Protocol-whitepaper.pdf?rlkey=hnf7t1acc7uimolu7xxhnxlp5&st=1ifkc8as&dl=0" target="blank" 
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary='true'
              dark='true'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-10}
            >
              <b>Whitepaper</b>
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
