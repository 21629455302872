import React from 'react';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements';




const Services = () => {
  return (
    <>
      <ServicesContainer id='services'>
        <ServicesH1></ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
         
            <ServicesH2>The version 1 is going to be released in Q1 2024. The second
and final version is set to go live on main net in Q4 2024</ServicesH2>
            <ServicesP>
            
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
         
            <ServicesH2>Fully Ethereum equivalent rollup</ServicesH2>
            <ServicesP>
            
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
           
            <ServicesH2>Increased number of TPS by using incrementally verifiable computation IVC for proof creation </ServicesH2>
            <ServicesP>
            
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
